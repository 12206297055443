// Copyright 2024 Diffblue Limited. All Rights Reserved.
// Unpublished proprietary source code.
// Use is governed by https://docs.diffblue.com/licenses/eula

import { Auth0Error, Auth0UserProfile, WebAuth } from "auth0-js";
import { toAuth0State } from "../email/UserDetails";
import { postTelemetryEvent } from "./HttpService";
import { toAuth0SSOState } from "../social/SocialUserDetails";

/**
 * Creates a magic link and sends it to the user via email.
 *
 * @param auth0 Auth0 {@link WebAuth} object
 * @param key Users license key
 * @param distinctId Users mixpanel distinct ID
 * @param ijVersion Version of users IntelliJ IDE
 */
export async function sendMagicLink(
  auth0: WebAuth,
  key: string,
  distinctId: string,
  ijVersion?: string
) {
  const firstName = (document.getElementById("first-name-input") as HTMLInputElement).value;
  const lastName = (document.getElementById("last-name-input") as HTMLInputElement).value;
  const email = (document.getElementById("email-input") as HTMLInputElement).value;
  const job = (document.getElementById("job-title-input") as HTMLInputElement).value;
  const company = (document.getElementById("company-input") as HTMLInputElement).value;

  const state = toAuth0State({
    key,
    distinctId,
    email,
    firstName,
    lastName,
    job,
    company,
    ijVersion
  });

  auth0.passwordlessStart(
    {
      connection: "email",
      send: "link",
      email,
      authParams: {
        redirectUri: process.env.REACT_APP_EMAIL_WEB_FORM_URL + "/verify",
        state
      }
    },
    (err, _) => {
      if (err) {
        console.log("Error sending the email");
        postTelemetryEvent("/on-error-sending-magic-link", distinctId);
        window.alert("Sorry, something went wrong. Please check your details and try again.");
      } else {
        postTelemetryEvent("/on-magic-link-sent", distinctId);
        window.alert("Please check your email for the verification link");
      }
    }
  );
}

/**
 * Starts SSO authentication
 *
 * This is done by sending the user to the social login's authorization page via Auth0
 *
 * @param auth0 Auth0 {@link WebAuth} object
 * @param connection Auth0 social login connection to authneticate with
 * @param key Users license key
 * @param distinctId Users mixpanel distinct ID
 * @param ijVersion Version of users IntelliJ IDE
 */
export function beginSSOLogin(
  auth0: WebAuth,
  connection: string,
  key: string,
  distinctId: string,
  job: string,
  company: string,
  ijVersion?: string
) {
  auth0.authorize({
    responseType: "token",
    connection,
    redirectUri: process.env.REACT_APP_EMAIL_WEB_FORM_URL + "/verify-sso",
    state: toAuth0SSOState(key, distinctId, job, company, ijVersion)
  });
}

/**
 * Fetches the user info from SSO login via Auth0
 *
 * @param auth0 Auth0 {@link WebAuth} object
 * @param distinctId Users mixpanel distinct ID
 * @param onError Function to call when we cannot get the users information
 * @param onSuccess Function to call on fetch success
 */
export function fetchUserInfo(
  auth0: WebAuth,
  distinctId: string,
  onError: (err: Auth0Error) => void,
  onSuccess: (res: Auth0UserProfile) => void
) {
  auth0.parseHash({ hash: window.location.hash }, (err, res) => {
    if (err) {
      console.log("Error parsing hash");
      postTelemetryEvent("/on-sso-error-fetching-details", distinctId);
      onError(err);
    } else if (res?.accessToken) {
      auth0.client.userInfo(res?.accessToken, (err, res) => {
        if (err) {
          console.log("Error fetching user info");
          postTelemetryEvent("/on-sso-error-fetching-details", distinctId);
          onError(err);
        } else {
          postTelemetryEvent("/on-sso-details-retrieved", distinctId);
          onSuccess(res);
        }
      });
    } else {
      console.log("Error fetching user access token");
      postTelemetryEvent("/on-sso-error-fetching-details", distinctId);
      onError({ error: "user access token missing" });
    }
  });
}
