// Copyright 2024 Diffblue Limited. All Rights Reserved.
// Unpublished proprietary source code.
// Use is governed by https://docs.diffblue.com/licenses/eula

import { WebAuth } from "auth0-js";
import googleSvg from "../assets/GoogleIcon.svg";
import githubSvg from "../assets/GithubIcon.svg";
import gitlabSvg from "../assets/GitlabIcon.svg";
import SocialLoginButton from "./SocialLoginButton";
import { beginSSOLogin } from "../service/Auth0Service";
import { useSearchParams } from "react-router-dom";
import { postTelemetryEvent } from "../service/HttpService";

interface Properties {
  /** Auth0 api object. */
  auth0: WebAuth;
  /** True if the user has entered in valid company details. */
  validCompanyDetails: boolean;
  /** The job title that the user has entered in. */
  job: string;
  /** The company name that the user has entered in. */
  company: string;
}

/**
 * Component providing social login to the web form.
 *
 * @returns A JSX element of Social Login component.
 */
function SocialLogin(props: Properties) {
  const [searchParams] = useSearchParams();

  // distinct ID & key is guaranteed here - otherwise this form wouldn't load.
  const distinctId = searchParams.get("distinctId")!;
  const key = searchParams.get("key")!;
  const ijVersion = searchParams.get("ijVersion") ?? undefined;

  return (
    <div className="text-center py-3">
      <SocialLoginButton
        isDisabled={!props.validCompanyDetails}
        socialLogin="Google"
        iconSrc={googleSvg}
        onClick={async () => {
          await postTelemetryEvent("/on-google-sso-clicked", distinctId);
          beginSSOLogin(
            props.auth0,
            "google-oauth2",
            key,
            distinctId,
            props.job,
            props.company,
            ijVersion
          );
        }}
      />
      <SocialLoginButton
        isDisabled={!props.validCompanyDetails}
        socialLogin="Github"
        iconSrc={githubSvg}
        onClick={async () => {
          await postTelemetryEvent("/on-github-sso-clicked", distinctId);
          beginSSOLogin(
            props.auth0,
            "github",
            key,
            distinctId,
            props.job,
            props.company,
            ijVersion
          );
        }}
      />
      <SocialLoginButton
        isDisabled={!props.validCompanyDetails}
        socialLogin="Gitlab"
        iconSrc={gitlabSvg}
        onClick={async () => {
          await postTelemetryEvent("/on-gitlab-sso-clicked", distinctId);
          beginSSOLogin(
            props.auth0,
            "gitlab",
            key,
            distinctId,
            props.job,
            props.company,
            ijVersion
          );
        }}
      />
    </div>
  );
}

export default SocialLogin;
