// Copyright 2024 Diffblue Limited. All Rights Reserved.
// Unpublished proprietary source code.
// Use is governed by https://docs.diffblue.com/licenses/eula

import { Auth0UserProfile } from "auth0-js";
import BasicUserDetails, { parseParamsIntoAuth0State } from "../BasicUserDetails";

/** Object representing the users' details from social login. */
interface SocialUserDetails extends BasicUserDetails {
  /** {@link Auth0UserProfile#given_name} */
  givenName: string;
  /** {@link Auth0UserProfile#family_name} */
  familyName: string;
}

/**
 * Parse the user details (from SSO & passed in through URL) in a combined
 * {@link SocialUserDetails} object.
 *
 * @param user User details as stored by Auth0
 * @param key Users license key
 * @param distinctId Users mixpanel distinct ID
 * @param company The company name the user has input
 * @param job The job title the user has input
 * @param ijVersion The users' IntelliJ version
 * @returns A {@link SocialUserDetails} object from the SSO integration
 */
export function parseAuth0UserDetails(
  user: Auth0UserProfile,
  key: string,
  distinctId: string,
  job: string,
  company: string,
  ijVersion?: string
): SocialUserDetails {
  return {
    key,
    distinctId,
    ijVersion,
    job,
    company,
    email: user.email || "",
    givenName: user.given_name || "",
    familyName: user.family_name || ""
  };
}

/**
 * Creates a state object that can be passed through the auth0 api.
 *
 * This state object will be sent through the URL, so we will be able to retrieve it
 * when auth0 redirects back to us.
 *
 * Auth0 will automatically escape special characters, so we will need to unescape this
 * object when parsing it.
 *
 * @param key Users license key
 * @param distinctId Users mixpanel distinct ID
 * @param ijVersion Version of users IntelliJ IDE
 *
 * @returns A state object to send to auth0
 */
export function toAuth0SSOState(
  key: string,
  distinctId: string,
  job: string,
  company: string,
  ijVersion?: string
): string {
  const params: [string, string][] = [
    ["key", key],
    ["distinctId", distinctId],
    ["job", job],
    ["company", company]
  ];
  if (ijVersion) {
    params.push(["ijVersion", ijVersion]);
  }
  return parseParamsIntoAuth0State(params);
}

/**
 * Converts a {@link SocialUserDetails} object into a string that we can
 * send to licensing-app-service via the body of a post request.
 *
 * @param details {@link SocialUserDetails}
 * @returns A string post request representing the {@link SocialUserDetails}
 */
export function toFormPostRequestBody(details: SocialUserDetails): string {
  let request =
    "key=" +
    details.key +
    "&distinctId=" +
    details.distinctId +
    "&email=" +
    encodeURIComponent(details.email) +
    "&firstName=" +
    encodeURIComponent(details.givenName) +
    "&lastName=" +
    encodeURIComponent(details.familyName) +
    "&job=" +
    encodeURIComponent(details.job) +
    "&company=" +
    encodeURIComponent(details.company);
  if (details.ijVersion) {
    request += "&ijVersion=" + details.ijVersion;
  }
  return request;
}
