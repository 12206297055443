// Copyright 2024 Diffblue Limited. All Rights Reserved.
// Unpublished proprietary source code.
// Use is governed by https://docs.diffblue.com/licenses/eula

import "./Root.css";
import { Outlet } from "react-router-dom";
import logo from "./assets/Diffblue-Logo-White.svg";

/**
 * Root component to wrap other pages inside.
 *
 * This should have generic styles & header etc. that all pages will want to share.
 *
 * @returns JSX component of page wrapper.
 */
function Root() {
  return (
    <div className="root">
      <div className="content-wrapper">
        <div className="header">
          <img src={logo} />
        </div>
        <Outlet />
      </div>
    </div>
  );
}

export default Root;
