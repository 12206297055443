// Copyright 2024 Diffblue Limited. All Rights Reserved.
// Unpublished proprietary source code.
// Use is governed by https://docs.diffblue.com/licenses/eula

import BasicUserDetails, {
  createParamsFromAuth0State,
  parseParamsIntoAuth0State
} from "../BasicUserDetails";

/** Object representing the users' details */
interface UserDetails extends BasicUserDetails {
  /** The users first name (entered by the user) */
  firstName: string;
  /** The users last name (entered by the user) */
  lastName: string;
}

/**
 * Creates a state object that can be passed through the auth0 api.
 *
 * This state object will be sent through the URL, so we will be able to retrieve it
 * when auth0 redirects back to us.
 *
 * Auth0 will automatically escape special characters, so we will need to unescape this
 * object when parsing it.
 *
 * @param details {@link UserDetails}
 * @returns A state object to send to auth0
 */
export function toAuth0State(details: UserDetails): string {
  const params: [string, string][] = [
    ["key", details.key],
    ["distinctId", details.distinctId],
    ["email", details.email],
    ["firstName", details.firstName],
    ["lastName", details.lastName],
    ["job", details.job],
    ["company", details.company]
  ];
  if (details.ijVersion) {
    params.push(["ijVersion", details.ijVersion]);
  }
  return parseParamsIntoAuth0State(params);
}

/**
 * Converts a {@link UserDetails} object into a string that we can
 * send to licensing-app-service via the body of a post request.
 *
 * Since we cannot know what the user has entered into the input fields,
 * we should encode them. This will require decoding again on the other
 * end. The key and distinctId should not need encoding as they will be
 * passed in by us, and shouldn't have any problematic characters.
 *
 * @param details {@link UserDetails}
 * @returns A string post request representing the {@link UserDetails}
 */
export function toFormPostRequestBody(details: UserDetails): string {
  let request =
    "key=" +
    details.key +
    "&distinctId=" +
    details.distinctId +
    "&email=" +
    encodeURIComponent(details.email) +
    "&firstName=" +
    encodeURIComponent(details.firstName) +
    "&lastName=" +
    encodeURIComponent(details.lastName) +
    "&job=" +
    encodeURIComponent(details.job) +
    "&company=" +
    encodeURIComponent(details.company);
  if (details.ijVersion) {
    request += "&ijVersion=" + details.ijVersion;
  }
  return request;
}

/**
 * Parse the Auth0 state URL search parameter
 *
 * This URL search parameter should contain the object that we created in {@link toAuth0State}.
 *
 * We will need to unescape the parameter, then reverse the creation process.
 *
 * Because we depend on the '=' and {@link URL_PARAM_SEPERATOR} symbols to reconstruct
 * the {@link UserDetails}, the user must not be allowed to enter either symbol in the form.
 *
 * @param state Auth0 state passed in via the "state" URL query parameter
 * @returns A {@link UserDetails} object
 */
export function parseAuth0State(state: string): UserDetails {
  const params = createParamsFromAuth0State(state);
  return {
    key: params.get("key") ?? "",
    distinctId: params.get("distinctId") ?? "",
    email: params.get("email") ?? "",
    firstName: params.get("firstName") ?? "",
    lastName: params.get("lastName") ?? "",
    job: params.get("job") ?? "",
    company: params.get("company") ?? "",
    ijVersion: params.get("ijVersion")
  };
}

/**
 * Provides light validation to ensure that we got back the user details correct.
 *
 * @param details {@link UserDetails}
 * @returns True if the user details are valid.
 */
export function isUserDetailsValid(details: UserDetails) {
  try {
    return (
      details.key.length > 0 &&
      details.distinctId.length > 0 &&
      details.email.length > 0 &&
      details.firstName.length > 0 &&
      details.lastName.length > 0 &&
      details.job.length > 0 &&
      details.company.length > 0
    );
  } catch (err) {
    // Catch any potentially undefined variables.
    return false;
  }
}
