// Copyright 2024 Diffblue Limited. All Rights Reserved.
// Unpublished proprietary source code.
// Use is governed by https://docs.diffblue.com/licenses/eula

/** Separator for our custom parameters that we'll pass through the magic link. */
const URL_PARAM_SEPERATOR = "|";

/** Symbol to join together our custom parameter key with value. */
const URL_PARAM_KEY_VALUE_SYMBOL = "=";

/**
 * Basic user details that we require from every user.
 */
export default interface BasicUserDetails {
  /** The users license key */
  key: string;
  /** The users mixpanel distinct ID */
  distinctId: string;
  /** The users email address (entered by the user) */
  email: string;
  /** The users job (entered by the user) */
  job: string;
  /** The users company (entered by the user) */
  company: string;
  /** Version of users IntelliJ IDE */
  ijVersion?: string;
}

/**
 * Parses parameters that we want to store in Auth0 state
 *
 * @param params parameters that we want to send
 * @returns a string to be passed into Auth0 state
 */
export function parseParamsIntoAuth0State(params: [string, string][]): string {
  return params
    .map((record) => record[0] + URL_PARAM_KEY_VALUE_SYMBOL + record[1])
    .join(URL_PARAM_SEPERATOR);
}

/**
 * Parses Auth0 state back into the parameters that we initially created
 *
 * @param state Auth0 state found in URL after redirect
 * @returns a map of parameters in the state
 */
export function createParamsFromAuth0State(state: string): Map<string, string> {
  const unescaped = decodeURIComponent(state);
  return new Map(
    unescaped.split(URL_PARAM_SEPERATOR).map((param) => {
      const split = param.split(URL_PARAM_KEY_VALUE_SYMBOL);
      return [split[0], split[1]];
    })
  );
}
