// Copyright 2024 Diffblue Limited. All Rights Reserved.
// Unpublished proprietary source code.
// Use is governed by https://docs.diffblue.com/licenses/eula

import "./App.css";
import { Routes, Route, useSearchParams } from "react-router-dom";
import Home from "./Home";
import VerifyEmail from "./email/VerifyEmail";
import Root from "./Root";
import { useEffect } from "react";
import { postTelemetryEvent } from "./service/HttpService";
import { WebAuth } from "auth0-js";
import VerifySocialLogin from "./social/VerifySocialLogin";

/**
 * App root. Contains initialization functions & routing.
 *
 * @returns JSX component of App root.
 */
function App() {
  const [searchParams] = useSearchParams();
  const auth0 = new WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN ?? "",
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? "",
    redirectUri: process.env.REACT_APP_EMAIL_WEB_FORM_URL + "/verify",
    responseType: "code",
    scope: "openid profile email"
  });

  useEffect(() => {
    const key = searchParams.get("key");
    const distinctId = searchParams.get("distinctId");
    if (key && distinctId) {
      sendTelemetryOnLoad(distinctId);
    }
  }, [searchParams]);

  return (
    <Routes>
      <Route element={<Root />} path="/">
        <Route element={<Home auth0={auth0} />} path="/" />
        <Route element={<VerifyEmail />} path="/verify" />
        <Route element={<VerifySocialLogin auth0={auth0} />} path="/verify-sso" />
      </Route>
    </Routes>
  );
}

/**
 * Sends a telemetry onLoad event, with the given distinctId.
 *
 * @param distinctId Mixpanel distinct ID of the user (passed in from IJ) to associate events with.
 */
async function sendTelemetryOnLoad(distinctId: string) {
  await postTelemetryEvent("/on-load", distinctId);
}

export default App;
