// Copyright 2024 Diffblue Limited. All Rights Reserved.
// Unpublished proprietary source code.
// Use is governed by https://docs.diffblue.com/licenses/eula

/**
 * Send a post request to the backend node.js licensing-app-service.
 *
 * @param endpoint The endpoint to send the request to
 * @param body The body of the request
 * @returns The response received
 */
export async function post(endpoint: string, body: string) {
  return await fetch(process.env.REACT_APP_LICENSING_APP_SERVICE_URL + endpoint, {
    method: "POST",
    body: body,
    headers: {
      "Content-type": "application/x-www-form-urlencoded"
    }
  });
}

/**
 * Type for valid telemetry event endpoints in licensing-app-service
 */
type TelemetryEndpoint =
  | "/on-load"
  | "/on-submit-details"
  | "/on-error-sending-magic-link"
  | "/on-magic-link-sent"
  | "/on-magic-link-received"
  | "/on-resend-magic-link"
  | "/on-reenter-details"
  | "/on-google-sso-clicked"
  | "/on-github-sso-clicked"
  | "/on-gitlab-sso-clicked"
  | "/on-sso-redirect"
  | "/on-sso-details-retrieved"
  | "/on-sso-error-fetching-details";

/**
 * Sends a post request to a telemetry endpoint
 *
 * @param endpoint Telemetry endpoint to send event with
 * @param distinctId Mixpanel distinct ID of the user
 * @returns The response received
 */
export async function postTelemetryEvent(endpoint: TelemetryEndpoint, distinctId: string) {
  return await post("/ce-email-form" + endpoint, "distinctId=" + distinctId);
}
