// Copyright 2024 Diffblue Limited. All Rights Reserved.
// Unpublished proprietary source code.
// Use is governed by https://docs.diffblue.com/licenses/eula

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { post, postTelemetryEvent } from "../service/HttpService";
import { isUserDetailsValid, parseAuth0State, toFormPostRequestBody } from "./UserDetails";
import VerificationPage from "../verify/VerificationPage";

/** Type defining the possible status for email verification. */
type VerifyStatus = "PENDING" | "OK" | "ERROR";

/**
 * Page to display when verifying the users email.
 *
 * This will be fired from an Auth0 callback - as a result, the URL should
 * already have the error inside it - or not if login was successful.
 *
 * @returns JSX element of page.
 */
function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<VerifyStatus>("PENDING");

  const state = searchParams.get("state");
  const userDetails = parseAuth0State(state ?? "");
  const distinctId = userDetails.distinctId;

  useEffect(() => {
    handleMagicLinkClicked();
  }, []);

  return <VerificationPage status={status} distinctId={distinctId} ceKey={userDetails.key} />;

  /**
   * Handles functions to be called when the site has been reached through clicking a magic link.
   */
  async function handleMagicLinkClicked() {
    await postTelemetryEvent("/on-magic-link-received", distinctId);
    // If there was an error, auth0 will pass an "error" & "desc" in the URL
    // They might also consume the error themselves: unfortunately, theres nothing we can do about this
    if (searchParams.has("error")) {
      console.log(
        "Error in resolving the magic link: %s, %s",
        searchParams.get("error"),
        searchParams.get("error_description")
      );
      setStatus("ERROR");
      return;
    }
    // Check to make sure that we recieved the users details okay.
    if (!isUserDetailsValid(userDetails)) {
      console.log("Error in retrieving user details");
      setStatus("ERROR");
      return;
    }
    try {
      // Call backend to store the user details & update the license.
      const res = await post(
        "/ce-email-form/store-user-details",
        toFormPostRequestBody(userDetails)
      );
      setStatus(res.ok ? "OK" : "ERROR");
    } catch (err) {
      setStatus("ERROR");
    }
  }
}

export default VerifyEmail;
