// Copyright 2024 Diffblue Limited. All Rights Reserved.
// Unpublished proprietary source code.
// Use is governed by https://docs.diffblue.com/licenses/eula

import { useState } from "react";

interface Properties {
  /** The users' job title. */
  job: string;
  /** State to set the users' input job. */
  setJob: (job: string) => void;
  /** The users' company name. */
  company: string;
  /** State to set the users' company name. */
  setCompany: (company: string) => void;
  /** State to set, defining if both the job & company inputs are valid */
  setValidJobAndCompany: (isValid: boolean) => void;
}

/**
 * Company details component allowing a user to input their company & job details prior to verification.
 *
 * @returns JSX element of company details input.
 */
function CompanyDetails(props: Properties) {
  const [completeDetails, setCompleteDetails] = useState(false);
  const [erroneousJob, setErroneousJob] = useState(false);
  const [erroneousCompany, setErroneousCompany] = useState(false);

  return (
    <div className="email-form">
      <div className="mt-3 d-flex gap-3">
        <div className="d-inline-flex flex-column w-50">
          <label className="form-label">Job title *</label>
          <input
            className={`form-control d-inline-block ${erroneousJob ? "is-invalid" : ""}`}
            type="text"
            id="job-title-input"
            placeholder="Job title"
            onChange={() => {
              const job = (document.getElementById("job-title-input") as HTMLInputElement).value;
              props.setJob(job);
              validateJobAndCompanyInput();
            }}
          ></input>
          <div className="invalid-feedback">Symbols are not permitted</div>
        </div>
        <div className="d-inline-flex flex-column w-50">
          <label className="form-label">Company name *</label>
          <input
            className={`form-control d-inline-block ${erroneousCompany ? "is-invalid" : ""}`}
            type="text"
            id="company-input"
            placeholder="Company"
            onChange={() => {
              const company = (document.getElementById("company-input") as HTMLInputElement).value;
              props.setCompany(company);
              validateJobAndCompanyInput();
            }}
          ></input>
          <div className="invalid-feedback">Symbols are not permitted</div>
        </div>
      </div>
      <p className={`mt-3 text-center required-field-prompt ${completeDetails ? "d-none" : ""}`}>
        * You must complete the above fields before continuing
      </p>
    </div>
  );

  /**
   * Validates the company & job input fields
   *
   * If not valid, users should not be able to continue verification &
   * we should display a warning.
   */
  function validateJobAndCompanyInput() {
    const job = (document.getElementById("job-title-input") as HTMLInputElement).value;
    const company = (document.getElementById("company-input") as HTMLInputElement).value;
    setCompleteDetails(job !== "" && company !== "");

    const disallowedRegex = /[|=%]/;
    const disallowedJob = job !== "" && disallowedRegex.test(job);
    const disallowedCompany = company !== "" && disallowedRegex.test(company);
    setErroneousJob(disallowedJob);
    setErroneousCompany(disallowedCompany);

    const validJob = job !== "" && !disallowedJob;
    const validCompany = company !== "" && !disallowedCompany;
    props.setValidJobAndCompany(validJob && validCompany);
  }
}

export default CompanyDetails;
