// Copyright 2024 Diffblue Limited. All Rights Reserved.
// Unpublished proprietary source code.
// Use is governed by https://docs.diffblue.com/licenses/eula

import { postTelemetryEvent } from "../service/HttpService";
import "./VerificationPage.css";

/** Type defining the possible status for email verification. */
type VerifyStatus = "PENDING" | "OK" | "ERROR";

interface Properties {
  status: VerifyStatus;
  distinctId: string;
  ceKey: string;
}

/**
 * Page to display the result of verification.
 *
 * Components that use this page should implement verification callbacks and pass through the status here.
 *
 * @returns JSX element of page.
 */
function VerificationPage(props: Properties) {
  const distinctId = props.distinctId;

  const header = (
    <h1 className="headline pt-5 w-50">Verify Your Diffblue Cover Community Edition</h1>
  );

  if (props.status === "PENDING") {
    return (
      <div>
        {header}
        <p>Please wait while we verify your version of Cover Plugin.</p>
      </div>
    );
  } else if (props.status === "OK") {
    return (
      <div>
        {header}
        <h2 className="pt-3">Success!</h2>
        <p className="pt-3">
          Your version of Cover Plugin Community Edition for IntelliJ has been successfully
          verified.
        </p>
        <p>You can now close this window and open IntelliJ again to continue using Cover Plugin.</p>
      </div>
    );
  } else {
    const homeUrl = `/ce-verification/?key=${props.ceKey}&distinctId=${distinctId}`;
    return (
      <div>
        {header}
        <p>We were unable to verify your version of Cover Plugin.</p>
        <p>
          Please{" "}
          <a
            className="click-here-link"
            href={homeUrl}
            onClick={(_) => postTelemetryEvent("/on-reenter-details", distinctId)}
          >
            click here
          </a>{" "}
          to re-enter your details.
        </p>
      </div>
    );
  }
}

export default VerificationPage;
