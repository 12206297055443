// Copyright 2024 Diffblue Limited. All Rights Reserved.
// Unpublished proprietary source code.
// Use is governed by https://docs.diffblue.com/licenses/eula

import "./EmailForm.css";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { postTelemetryEvent } from "../service/HttpService";
import { WebAuth } from "auth0-js";
import { sendMagicLink } from "../service/Auth0Service";

interface Properties {
  /** Auth0 api object. */
  auth0: WebAuth;
  /** True if the user has entered in valid company details. */
  validCompanyDetails: boolean;
}

/**
 * Email form to allow the user to input an email for us to verify & send a link to.
 *
 * @returns JSX element of email form.
 */
function EmailForm(props: Properties) {
  const [formInputComplete, setFormInputComplete] = useState(false);
  const [erroneousFirstName, setErroneousFirstName] = useState(false);
  const [erroneousLastName, setErroneousLastName] = useState(false);
  const [erroneousEmail, setErroneousEmail] = useState(false);
  const [searchParams] = useSearchParams();
  return (
    <div className="email-form">
      <div className="pt-3 d-flex gap-3">
        <div className="d-inline-flex flex-column w-50">
          <label className="form-label">First name *</label>
          <input
            className={`form-control d-inline-block ${erroneousFirstName ? "is-invalid" : ""}`}
            type="text"
            id="first-name-input"
            placeholder="First name"
            onChange={() => validateFormInput()}
          ></input>
          <div className="invalid-feedback">Symbols are not permitted</div>
        </div>
        <div className="d-inline-flex flex-column w-50">
          <label className="form-label">Last name *</label>
          <input
            className={`form-control d-inline-block ${erroneousLastName ? "is-invalid" : ""}`}
            type="text"
            id="last-name-input"
            placeholder="Last name"
            onChange={() => validateFormInput()}
          ></input>
          <div className="invalid-feedback">Symbols are not permitted</div>
        </div>
      </div>
      <div className="mt-3">
        <label className="form-label">Work email *</label>
        <input
          className={`form-control ${erroneousEmail ? "is-invalid" : ""}`}
          type="email"
          id="email-input"
          placeholder="name@example.com"
          onChange={() => validateFormInput()}
        ></input>
        <div className="invalid-feedback">Please input a valid email address</div>
      </div>
      <p className="mt-3 privacy-notice">
        We will process your personal data in accordance with our{" "}
        <a href="https://www.diffblue.com/privacy/">Privacy Notice</a>
      </p>
      <button
        className="btn btn-primary email-submit"
        disabled={isSubmitDisabled()}
        onClick={async () => {
          // distinct ID & key is guaranteed here - otherwise this form wouldn't load.
          const distinctId = searchParams.get("distinctId")!;
          const key = searchParams.get("key")!;
          const ijVersion = searchParams.get("ijVersion") ?? undefined;
          await postTelemetryEvent("/on-submit-details", distinctId);
          await sendMagicLink(props.auth0, key, distinctId, ijVersion);
        }}
      >
        Submit
      </button>
      <p className={`mt-3 text-center required-field-prompt ${formInputComplete ? "d-none" : ""}`}>
        * You must complete all required fields before submitting
      </p>
    </div>
  );

  /**
   * @returns True if the form submit button should be disabled.
   */
  function isSubmitDisabled() {
    return (
      !formInputComplete ||
      erroneousFirstName ||
      erroneousLastName ||
      erroneousEmail ||
      !props.validCompanyDetails
    );
  }

  /**
   * Validates the form input.
   *
   * If valid, the user should be allowed to submit their details.
   * Otherwise, the submit button should be disabled.
   */
  function validateFormInput() {
    const firstName = (document.getElementById("first-name-input") as HTMLInputElement).value;
    const lastName = (document.getElementById("last-name-input") as HTMLInputElement).value;
    const emailInput = document.getElementById("email-input") as HTMLInputElement;
    const email = emailInput.value;

    // Validate all fields have a value before user can submit
    const validForm = firstName.length > 0 && lastName.length > 0 && email.length > 0;
    possiblySetErroneous(validForm, formInputComplete, setFormInputComplete);

    // Validate no fields contain disallowed values
    const disallowedRegex = /[|=%]/;
    possiblySetErroneous(
      disallowedRegex.test(firstName),
      erroneousFirstName,
      setErroneousFirstName
    );
    possiblySetErroneous(disallowedRegex.test(lastName), erroneousLastName, setErroneousLastName);
    // For the email, also perform a simple .checkValidity, but only if not empty.
    const invalidEmail =
      disallowedRegex.test(email) || (email.length > 0 && !emailInput.checkValidity());
    possiblySetErroneous(invalidEmail, erroneousEmail, setErroneousEmail);
  }

  /**
   * Sets or unsets and erroneous react state.
   *
   * After calling this function, the state will always be equal to {@param correctState}.
   *
   * If the {@param currentState} was already {@param correctState}, then no state changes will be made.
   *
   * @param correctState The correct state to be applied
   * @param currentState The current state
   * @param setAction action to set the state, if it needs to be set.
   */
  function possiblySetErroneous(
    correctState: boolean,
    currentState: boolean,
    setAction: (val: boolean) => void
  ) {
    if (correctState !== currentState) {
      setAction(correctState);
    }
  }
}

export default EmailForm;
