// Copyright 2024 Diffblue Limited. All Rights Reserved.
// Unpublished proprietary source code.
// Use is governed by https://docs.diffblue.com/licenses/eula

import { useSearchParams } from "react-router-dom";
import "./Home.css";
import EmailForm from "./email/EmailForm";
import { WebAuth } from "auth0-js";
import SocialLogin from "./social/SocialLogin";
import CompanyDetails from "./companyDetails/CompanyDetails";
import { useState } from "react";

/**
 * Home page to display before user has verified their CE plugin.
 *
 * @param props Properties for component
 * @returns JSX component of page.
 */
function Home(props: { auth0: WebAuth }) {
  const [searchParams] = useSearchParams();
  const [job, setJob] = useState("");
  const [company, setCompany] = useState("");
  const [validCompanyDetails, setValidCompanyDetails] = useState(false);

  if (!searchParams.has("key") || !searchParams.has("distinctId")) {
    const ceDownloadUrl = "https://diffblue.com/community-edition/download/";
    const jbrMarketplaceUrl =
      "https://plugins.jetbrains.com/plugin/14946-diffblue-cover--ai-for-code";
    return (
      <div className="download-plugin-info">
        <p>Please activate your Community Edition license in IntelliJ.</p>
        <p>
          You can download Cover Plugin for IntelliJ at:
          <ul>
            <li>
              <a href={ceDownloadUrl}>{ceDownloadUrl}</a>, or
            </li>
            <li>
              <a href={jbrMarketplaceUrl}>{jbrMarketplaceUrl}</a>
            </li>
          </ul>
        </p>
      </div>
    );
  }

  return (
    <div className="home-container pt-5 flex-shrink-2">
      <div className="info">
        <h1 className="headline">Verify Your Diffblue Cover Community Edition</h1>
        <h2 className="pt-3">
          Free. <b>Forever!</b>
        </h2>
        <p className="description pt-4">
          The Community Edition of Diffblue Cover is provided free for use to individual developers
          for non-commercial, open source and academic projects.
        </p>
        <div className="bullet-points pt-2">
          <strong>Verify your product to continue access to:</strong>
          <ul>
            <li>Autonomous-AI unit test generation</li>
            <li>Diffblue Cover via IntelliJ</li>
            <li>Tests written for individual classes or methods</li>
            <li>Diffblue Community forum support</li>
          </ul>
        </div>
      </div>
      <div className="form flex-shrink-1">
        <h3 className="form-title">Continue Using Diffblue Cover</h3>
        <CompanyDetails
          job={job}
          setJob={setJob}
          company={company}
          setCompany={setCompany}
          setValidJobAndCompany={setValidCompanyDetails}
        />
        <div className="position-relative mt-4">
          <hr />
          <span className="position-absolute or-divider-span">and</span>
        </div>
        <SocialLogin
          auth0={props.auth0}
          job={job}
          company={company}
          validCompanyDetails={validCompanyDetails}
        />
        <div className="position-relative">
          <hr />
          <span className="position-absolute or-divider-span">or</span>
        </div>
        <EmailForm auth0={props.auth0} validCompanyDetails={validCompanyDetails} />
      </div>
    </div>
  );
}

export default Home;
